import { api } from '../api'

const ChatRepository = {
    fetchThreads (query = {}) {
        return api().$get('/messages', query)
    },

    fetchThread (threadId) {
        return api().$get(`/messages/${threadId}`)
    },

    fetchMessagesFromThread (threadId, query = {}) {
        return api().$get(`/messages/${threadId}/messages`, query)
    },

    createThread (payload) {
        return api().$post('/messages', payload)
    },

    postMessage (threadId, payload) {
        return api().$post(`/messages/${threadId}`, payload)
    },

    deleteMessage (messageId) {
        return api().$delete(`/messages/${messageId}`)
    },

    addParticipants (threadId, payload) {
        return api().$post(`/messages/${threadId}/users`, payload)
    }
}

export default ChatRepository
